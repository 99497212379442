import React from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";


import Contact from "@components/contact";

import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact || Prompt Breeders">
      <Header />
      
      <Contact />

      <Footer />
      <MobileNav />
    </Layout>
  );
};

export default ContactPage;
